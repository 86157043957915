import { gql } from 'graphql-request'

export const GET_BOOK_STORY_SETTINGS = gql`
  query BookStorySettings {
    bookStorySettings {
      id
      name
      maxCharacter
    }
  }
`
