import { gql } from 'graphql-request'

export const GET_BOOK_BY_USER_ID = gql`
  query GetBookByUserId(
    $id: Int!
    $bookType: BookType
    $penNameId: Int
    $page: Int
    $limitPerPage: Int
  ) {
    getBookByUserId(
      id: $id
      bookType: $bookType
      penNameId: $penNameId
      page: $page
      limitPerPage: $limitPerPage
    ) {
      data {
        id
        title
        isEnded
        isEbook
        writer
        penName {
          firstPenName
          user {
            writer {
              affiliation
            }
            displayName
            profilePageSlug
            avatarImgPath
          }
        }
        tags {
          id
          name
        }
        category {
          id
          name
        }
        coverImgPath
        coverResizeImgPath
        cover
        bookType
        likeCount
        commentCount
        viewCount
        isLiked
        isTranslated
        newChapter {
          id
          chapterName
        }
        publishedChapterCount
        status
        writingType
        publishedAt
        isEpub
        isPdf
      }
      total
      page
      totalBook
    }
  }
`
