// eslint-disable-next-line max-classes-per-file
import { Exclude, Expose, Transform, Type } from 'class-transformer'

import { BookCharacterType } from '@models/book/BookCharacterType'
import { BookSupporterType } from '@models/book/BookSupporterType'
import { BookCategoryType } from '@models/book/BookCategoryType'
import { BookLinkType } from '@models/book/BookLinkType'
import { BookRatingType } from '@models/book/BookRatingType'
import { BookTagsType } from '@models/book/BookTagsType'
import { BookEnum } from '@interfaces/BookEnum'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { ChapterTypeEnum } from '@interfaces/ChapterTypeEnum'
import { StoryFieldType } from './StoryFieldType'

class UserType {
  id: number

  displayName: string

  avatarImgPath: string

  profilePageSlug: string
}

class PenNameType {
  id: number

  firstPenName: string

  @Type(() => UserType)
  user: UserType
}

@Exclude()
export class StoryDetailType {
  @Expose() id: number

  @Expose() writer: string

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose() coverImgPath: string

  @Expose() title: string

  @Expose() cover: string

  @Expose()
  @Type(() => BookCategoryType)
  category: BookCategoryType

  @Expose()
  @Transform(({ value }) =>
    value === WritingEnum.ORIGINAL
      ? 'ออริจินอล'
      : value === WritingEnum.FAN_FICTION
      ? 'แฟนฟิกชัน'
      : ''
  )
  writingType: string

  @Expose() isTranslated: boolean

  @Expose()
  @Type(() => BookRatingType)
  rating: BookRatingType

  @Expose() likeCount: number

  @Expose() viewCount: number

  @Expose() shelfAddedCount: number

  @Expose() lastCommentDate: string

  @Expose() commentCount: number

  @Expose() isEpub: boolean

  @Expose() isPdf: boolean

  @Expose() isEnded: boolean

  @Expose() isVisible: boolean

  @Expose() isFreeBook: boolean

  @Expose()
  @Type(() => BookTagsType)
  tags: BookTagsType[]

  @Expose() intro: string

  @Expose() naiinCoverImgPath: string

  @Expose() naiinTitle: string

  @Expose() naiinCover: string

  @Expose() naiinLink: string

  @Expose() updatedAt: string

  @Expose() publishedAt: string

  @Expose() createdAt: string

  @Expose({ name: 'bookLink' })
  @Type(() => BookLinkType)
  bookLinks: BookLinkType[]

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  status: BookStatusEnum

  @Expose() bookType: BookEnum

  @Expose() chapterType: ChapterTypeEnum

  @Expose()
  @Type(() => BookCharacterType)
  characters: BookCharacterType[]

  // @Expose({ name: 'bookChapters' })
  // @Type(() => BookChapterType)
  // @Transform(({ value }) =>
  //   (value as BookChapterType[])
  //     .sort((a, b) => a.order - b.order)
  //     .map((item, index) => ({ ...item, chapterNo: index + 1 }))
  // )
  // chapters: BookChapterType[]

  @Expose({ name: 'chapterCount' })
  totalChapter: number

  @Expose({ name: 'publishedChapterCount' })
  totalPublishedChapter: number

  @Expose() totalNotFreeChapter: number

  @Expose() totalChapterPrice: number

  @Expose()
  @Type(() => BookSupporterType)
  supporters: BookSupporterType[]

  @Expose()
  @Type(() => StoryFieldType)
  bookStory: StoryFieldType[]
}
