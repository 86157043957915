import { Expose, Transform } from 'class-transformer'

export class StoryForShareType {
  @Expose() id: number

  @Expose() title: string

  @Expose() cover: string

  @Expose() @Transform(({ obj }) => obj.category.name) categoryName: string

  @Expose()
  @Transform(({ obj }) => obj.penName.firstPenName)
  firstPenName: string

  @Expose()
  @Transform(({ obj }) => obj.tags.map((row: { name: string }) => row.name))
  tags: string[]

  @Expose()
  coverImgPath: string

  @Expose() facebookShareImageUrl: string

  @Expose() twitterShareImageUrl: string
}
