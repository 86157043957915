import { Expose, Transform, Type } from 'class-transformer'

import { ImageType } from '@interfaces/ImageType'
import { OrientationEnum } from '@interfaces/OrientationEnum'
import { CharacterType } from '@models/myWriting/CharacterType'
import { BookEnum } from '@interfaces/BookEnum'
import { ChapterTypeEnum } from '@interfaces/ChapterTypeEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { StoryFieldType } from '@models/story/StoryFieldType'
import { BookLinkType } from '../myWriting/BookLinkType'
import { TagType } from './TagType'

export class StoryFormType {
  @Expose() id?: number

  @Expose() title: string

  @Expose() cover: string

  @Expose()
  @Transform(({ value }) => value?.toUpperCase())
  bookType: BookEnum

  @Expose()
  @Transform(({ value }) => value?.toUpperCase())
  chapterType: ChapterTypeEnum

  @Expose()
  @Transform(({ value }) => value?.toUpperCase())
  orientation?: OrientationEnum

  @Expose()
  @Transform(({ value }) => value?.toUpperCase(), { toClassOnly: true })
  writingType: WritingEnum

  @Expose()
  @Transform(({ value }) => value ?? '', { toClassOnly: true })
  writer: string

  @Expose()
  @Transform(({ obj }) => obj.category?.id, { toClassOnly: true })
  categoryId?: number

  @Expose()
  @Transform(({ obj }) => obj.rating?.id, { toClassOnly: true })
  ratingId?: number

  @Expose()
  @Transform(({ obj }) => obj.penName?.id, { toClassOnly: true })
  penNameId?: number

  @Expose()
  @Transform(({ obj }) => obj.penName?.user?.id, { toClassOnly: true })
  penNameUserId?: number

  @Expose({ toClassOnly: true })
  @Type(() => CharacterType)
  characters: CharacterType[]

  @Expose()
  @Transform(({ value }) => value.map((tag: TagType) => tag.name))
  tags: string[]

  @Expose()
  @Transform(
    ({ value }) => {
      return value.url
    },
    { toPlainOnly: true }
  )
  @Transform(({ obj }) => ({ url: obj?.coverImgPath }), {
    toClassOnly: true,
  })
  coverImgPath: ImageType

  @Expose() coverResizeImgPath: string

  @Expose() isTranslated: boolean

  @Expose()
  @Transform(({ value }) => value.url, { toPlainOnly: true })
  @Transform(({ value }) => ({ url: value }), {
    toClassOnly: true,
  })
  naiinCoverImgPath: ImageType

  @Expose()
  @Transform(({ value }) => (typeof value === 'string' ? value?.trim() : ''))
  naiinTitle: string

  @Expose()
  @Transform(({ value }) => (typeof value === 'string' ? value?.trim() : ''))
  naiinCover: string

  @Expose()
  @Transform(({ value }) => (typeof value === 'string' ? value?.trim() : ''))
  naiinLink: string

  @Expose({ toClassOnly: true })
  @Type(() => BookLinkType)
  @Transform(({ obj }) => obj.bookLink?.slice(0, 1)?.pop() || {})
  linkOther: BookLinkType

  @Expose({ toClassOnly: true })
  @Type(() => BookLinkType)
  @Transform(({ obj }) => obj.bookLink?.slice(1))
  links: BookLinkType[]

  @Expose()
  @Transform(({ value }) => (value as number).toLocaleString())
  price: string

  @Expose() intro: string

  @Expose() status: BookStatusEnum

  @Expose() screenCapturable: boolean

  @Expose() offlineReadable: boolean

  @Expose() commentable: boolean

  @Expose() guestCommentable: boolean

  @Expose() isEnded: boolean

  @Expose() isPdf: boolean

  @Expose() isEpub: boolean

  @Expose()
  @Type(() => StoryFieldType)
  bookStory: StoryFieldType[]

  @Expose()
  @Transform(
    ({ obj }) =>
      !!obj.naiinCoverImgPath ||
      !!obj.naiinTitle?.trim() ||
      !!obj.naiinCover?.trim() ||
      !!obj.naiinLink?.trim()
  )
  isValidateNaiinField: boolean

  @Expose()
  requireAgeVerify: boolean
}
