import { gql } from 'graphql-request'

export const CREATE_BOOK_LINKS = gql`
  mutation BulkCreateBookLink(
    $bulkCreateBookLinkInput: [CreateBookLinkInput!]!
  ) {
    bulkCreateBookLink(bulkCreateBookLinkInput: $bulkCreateBookLinkInput) {
      id
      detail
      link
    }
  }
`
