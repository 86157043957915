import { gql } from 'graphql-request'

export const TOGGLE_BOOK_LIKE = gql`
  mutation ToggleBookLike($input: UserBookLikeInput!) {
    toggleBookLike(input: $input) {
      type
      status
    }
  }
`
