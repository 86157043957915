import { Expose } from 'class-transformer'

export class BookStorySettingType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  maxCharacter: number
}
