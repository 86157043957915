import { gql } from 'graphql-request'

export const REMOVE_BOOK_LINKS = gql`
  mutation BulkRemoveBookLink($ids: [Int!]!) {
    bulkRemoveBookLink(ids: $ids) {
      id
      deletedAt
    }
  }
`
