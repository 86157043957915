import { plainToInstance } from 'class-transformer'

import { CREATE_BOOK_LINKS } from '@client/collections/BookLink/schemas/createBookLink'
import { REMOVE_BOOK_LINKS } from '@client/collections/BookLink/schemas/removeBookLink'
import { UPDATE_BOOK_LINKS } from '@client/collections/BookLink/schemas/updateBookLink'
import { gqlApiInstance } from '@client/init'
import { BookLinkType } from '@models/myWriting/BookLinkType'

export function useBookLinkAction() {
  async function createBookLinks(
    data: {
      detail: string
      link: string
      bookId: number
    }[]
  ): Promise<BookLinkType[]> {
    const { bulkCreateBookLink } = await gqlApiInstance.request(
      CREATE_BOOK_LINKS,
      {
        bulkCreateBookLinkInput: data,
      }
    )

    return plainToInstance(BookLinkType, bulkCreateBookLink as [])
  }

  async function updateBookLinks(
    data: BookLinkType[]
  ): Promise<BookLinkType[]> {
    const { bulkUpdateBookLink } = await gqlApiInstance.request(
      UPDATE_BOOK_LINKS,
      {
        bulkUpdateBookLinkInput: data,
      }
    )

    return plainToInstance(BookLinkType, bulkUpdateBookLink as [])
  }

  async function removeBookLinks(ids: number[]): Promise<void> {
    await gqlApiInstance.request(REMOVE_BOOK_LINKS, {
      ids,
    })
  }

  return {
    createBookLinks,
    updateBookLinks,
    removeBookLinks,
  }
}
