import { gql } from 'graphql-request'

export const UPDATE_BOOK_LINKS = gql`
  mutation BulkUpdateBookLink(
    $bulkUpdateBookLinkInput: [UpdateBookLinkInput!]!
  ) {
    bulkUpdateBookLink(bulkUpdateBookLinkInput: $bulkUpdateBookLinkInput) {
      id
      detail
      link
    }
  }
`
