import { Expose } from 'class-transformer'
import { BookType } from '@models/book/BookType'

export class BookTopChartsType {
  @Expose() id: number

  @Expose() currentRank: number

  @Expose() previousRank: number

  @Expose() rankChange: number

  @Expose() viewCount: number

  @Expose() likeCount: number

  @Expose() totalViewCount: number

  @Expose() totalLikeCount: number

  @Expose() book: BookType
}
