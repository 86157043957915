import { gql } from 'graphql-request'

export const GET_STORY_FOR_SHARE = gql`
  query GetBookForShare($getBookReaderId: Int!) {
    getBookReader(id: $getBookReaderId) {
      id
      coverImgPath
      title
      cover
      penName {
        firstPenName
      }
      category {
        name
      }
      tags {
        name
      }
      facebookShareImageUrl
      twitterShareImageUrl
    }
  }
`
