import { gql } from 'graphql-request'

export const CREATE_STORY = gql`
  mutation CreateBook($createBookInput: CreateBookInput!) {
    createBook(createBookInput: $createBookInput) {
      id
      writer
      penName {
        id
      }
      skuId
      rating {
        id
      }
      tags {
        id
        name
      }
      category {
        id
      }
      chapterType
      coverImgPath
      title
      intro
      cover
      orientation
      naiinCoverImgPath
      naiinTitle
      naiinCover
      naiinLink
      price
      status
      isTranslated
      writingType
      screenCapturable
      commentable
      guestCommentable
      offlineReadable
      isEpub
      isPdf
      isEnded
      bookType
      publishedAt
      isEbook
      bookStory {
        name
        content
      }
    }
  }
`
