import { Expose } from 'class-transformer'

export class BookLinkType {
  @Expose() id?: number

  @Expose() detail: string

  @Expose() link: string

  deletedAt?: string

  isDeleted?: boolean
}
