import { gql } from 'graphql-request'

export const GET_STORY_DETAIL = gql`
  query GetBook($id: Int!) {
    book(id: $id) {
      id
      coverImgPath
      title
      writer
      cover
      writingType
      chapterType
      isTranslated
      likeCount
      viewCount
      shelfAddedCount
      commentCount
      isEpub
      isEnded
      isPdf
      isVisible
      isFreeBook
      intro
      naiinCoverImgPath
      naiinTitle
      naiinCover
      naiinLink
      status
      bookType
      chapterCount
      publishedChapterCount
      totalNotFreeChapter
      totalChapterPrice
      penName {
        id
        firstPenName
        user {
          id
          displayName
          avatarImgPath
        }
      }
      rating {
        id
        name
      }
      tags {
        id
        name
      }
      category {
        id
        name
      }
      bookLink {
        id
        detail
        link
      }
      supporters {
        id
        displayName
        username
        avatarImgPath
        profilePageSlug
      }
      bookStory {
        name
        content
      }
    }
  }
`
