import { Expose, Type } from 'class-transformer'
import { BookType } from '@models/bookCollection/BookType'

export class BookByUserResponse {
  @Expose()
  @Type(() => BookType)
  data: BookType[]

  @Expose()
  page: number

  @Expose()
  total: number

  @Expose()
  totalBook: number
}
