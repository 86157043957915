import { gql } from 'graphql-request'

export const GET_STORY = gql`
  query Book($id: Int!) {
    book(id: $id) {
      id
      writer
      penName {
        id
        user {
          id
        }
      }
      skuId
      rating {
        id
      }
      tags {
        id
        name
      }
      category {
        id
      }
      chapterType
      coverImgPath
      coverResizeImgPath
      title
      intro
      cover
      orientation
      naiinCoverImgPath
      naiinTitle
      naiinCover
      naiinLink
      price
      status
      isTranslated
      writingType
      screenCapturable
      commentable
      guestCommentable
      offlineReadable
      isEpub
      isPdf
      isEnded
      bookType
      commentCount
      isEbook
      requireAgeVerify
      characters {
        id
        characterRole {
          id
        }
        imgPath
        name
        description
        visible
        donatable
        publishedAt
      }
      bookLink {
        id
        detail
        link
      }
      bookStory {
        name
        content
      }
    }
  }
`
